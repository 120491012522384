<template>
  <div class="project">
    <project-hero />
    <project-content />
  </div>
</template>
<script>
import ProjectHero from "@/components/project/ProjectHero.vue";
import ProjectContent from "@/components/project/ProjectContent.vue";

export default {
  components: {
    ProjectHero,
    ProjectContent
  },
  metaInfo: {
    title: "Projects",
  },
};
</script>
