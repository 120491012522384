<template>
  <div class="project__row" data-aos="zoom-in">
    <div class="project__picture-container left">
      <picture>
        <source
          :srcset="require(`@/assets/images/project/${project.path}.webp`)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/project/${project.path}.png`)"
          type="image/jpeg"
        />
        <img
          class="project__pic"
          :src="require(`@/assets/images/project/${project.path}.png`)"
          alt="project__pic"
          width="691px"
          height="491px"
        />
      </picture>
    </div>
    <div class="project__detail right">
      <div class="project__subtitle">{{ project.subTitle }}</div>
      <div class="project__cilent">{{ project.title }}</div>
      <div class="project__description">
        {{ project.details }}
      </div>
      <div v-if="project.hasScore">
        <gtmetrix-score
          :perf="project.score.performance"
          :struct="project.score.structure"
        ></gtmetrix-score>
      </div>
      <div class="project__button-container">
        <green-button
          class="right__button green_button"
          title="VISIT SITE"
          :link="project.link"
        />
      </div>
    </div>
  </div>
</template>
<script>
import GreenButton from "@/components/GreenButton";
import GtmetrixScore from "@/components/project/GTMetrixScore";
export default {
  props: ["project"],
  components: {
    GreenButton,
    GtmetrixScore,
  },
};
</script>
<style scoped>
.project__pic {
  width: 100% !important;
  height: auto !important;
}
.project__picture-container {
  min-height: 100px;
}

.project__detail {
  text-transform: uppercase;
  text-align: center;
}

.project__content {
  margin-left: 5vw;
  margin-right: 5vw;
}
.project__title-container {
  padding-bottom: 30px;
}
.project__title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #777777;
}
.project__subtitle {
  color: #3ddbcf;
  font-weight: 700;
  padding-bottom: 8px;
  font-size: 12px;
}
.project__cilent {
  color: #00a99d;
  font-weight: 900;
  font-size: 17px;
  padding-bottom: 8px;
}
.project__description {
  color: #777777;
  font-weight: 900;
  font-size: 11px;
  padding-bottom: 8px;
}
.project__button-container {
  padding: 5px;
}
.project__row {
  vertical-align: middle;
  margin-top: 50px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1024px) {
  .green_button {
    margin: auto;
  }
  .mobile-view {
    display: block;
  }
  .laptop-view {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .laptop-view {
    display: block;
  }
  .mobile-view {
    display: none !important;
  }
  .green_button {
    margin: none !important;
  }
  .right {
    text-align: right;
  }
  .left {
    text-align: left;
  }
  .right__button {
    margin-left: auto !important;
  }
  .left__button {
    margin-right: auto !important;
  }

  .project__title {
    font-size: 16px;
    font-weight: 700;
  }
  .project__title-container {
    text-align: left;
  }
  .project__content {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .project__picture-container,
  .project__detail {
    display: inline-block;
    vertical-align: middle;
  }
  .project__detail {
    width: 40%;
  }
  .project__picture-container {
    width: 60%;
  }
  .right {
    text-align: right;
  }
  .project__subtitle {
    font-size: 23px;
  }
  .project__cilent {
    font-size: 28px;
  }
  .project__description {
    font-size: 15px;
  }
}
</style>
