<template>
  <div class="about-section">
    <div class="title__content">
      <span class ="title">Our Team</span> <br/>
      <span class ="desc">Founding Members</span>
    </div>
    <div class ="profile__container">
    <profile-component v-for="profile in profiles" :key="profile.id" :profile="profile" />
    </div>
      <span class ="desc">Development Team</span> <br/>
      <span class ="temp"><b>Coming Soon...</b></span>
  </div>
</template>
<script>
import ProfileComponent from "./ProfileComponent";
export default {
  data() {
    return {
      profiles: [
        {
          id: 1,
          name: "Jowett Chng",
          path: "jowett",
          role: "Founder, CEO",
        },
        {
          id: 2,
          name: "Jayden Lim",
          path: "jayden",
          role: "Co-Founder, COO",
        },
      ],
    };
  },
  components: {
    ProfileComponent,
  },
};
</script>
<style lang="scss" scoped>
.title__content{
  margin:50px

}
.title{
font-weight: 900;
    font-size: 35px;
}
.desc{
  font-size:15px;
}
.about-section {
  width: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
}
.profile__container{
  display:flex;
  justify-content: center;
}

</style>
