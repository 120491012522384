<template>
  <div class="service">
    <service-group/>
        <picture>
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/home/hero/hero-mobile.webp`)"
        type="image/webp"
      />
      <source
        media="(max-width: 500px)"
        :srcset="require(`@/assets/images/home/hero/hero-mobile.png`)"
        type="image/png"
      />
      <source
        :srcset="require(`@/assets/images/home/hero/hero-web.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/home/hero/hero-web.png`)"
        type="image/png"
      />
      <img
        :src="require(`@/assets/images/footer/footer-web.png`)"
        alt="Footer curve"
         class = "service__curve"
      />
    </picture>
    <!-- <project-hero />
    <project-content /> -->
    
  </div>
</template>
<script>
import ServiceGroup from "@/components/service/ServiceGroup.vue";
// import ProjectContent from "@/components/ProjectSection/ProjectContent.vue";

export default {
  data() {
    return {
      services: {
            webDev: [
                {
                    id: 1,
                    title: "Corporate Website",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
                {
                    id: 2,
                    title: "Portfolio Website",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
                {
                    id: 3,
                    title: "E-Commerce Website ",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
                {
                    id: 4,
                    title: "Web Security",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
            ],
            teleDev: [
                {
                    id: 1,
                    title: "Telegram Bot",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
            ],
            solutionDev: [
                {
                    id: 1,
                    title: "Corporate Website",
                    details: "",
                    reason: "",
                    points:[
                        'point 1',
                        'point 2',
                        'point 3',
                    ]
                },
            ],
      },
    };
  },
  components: {
    ServiceGroup,
    // ProjectContent
  },
  metaInfo: {
    title: "Services",
  },
};
</script>

<style>
.service__curve{
  width:100%
}
</style>
