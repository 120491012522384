<template>
  <section class="projectPage__section">
    <ProjectDetails
      v-for="project in projects"
      :key="project.id"
      :project="project"
    />
  </section>
</template>
<script>
import ProjectDetails from "./ProjectDetails.vue";
export default {
  data() {
    return {
      projects: [
        {
          id: 8,
          subTitle: "DALGONA GAME WEBSITE",
          title: "NATIONAL UNIVERSITY HOSPITAL (NUH)",
          details:
            "FRONT-END & BACK-END DEVELOPMENT, HOSTING",
          link: "/nuhs_dalgona",
          path: "nuhs_dalgona",
          hasScore: true,
           score: {
            performance: 98,
            structure: 100,
          },
        },
        {
          id: 10,
          subTitle: "WORDLE GAME WEBSITE",
          title: "NATIONAL UNIVERSITY HOSPITAL (NUH)",
          details:
            "FRONT-END & BACK-END DEVELOPMENT, HOSTING",
          link: "/nuhs_wordle",
          path: "nuhs_wordle",
          hasScore: true,
           score: {
            performance: 98,
            structure: 100,
          },
        },
        {
          id: 9,
          subTitle: "BALLOON GAME WEBSITE",
          title: "NATIONAL UNIVERSITY HOSPITAL (NUH)",
          details:
            "FRONT-END & BACK-END DEVELOPMENT, HOSTING",
          link: "/nuhs_balloon",
          path: "nuhs_balloon",
          hasScore: true,
           score: {
            performance: 98,
            structure: 100,
          },
        },
        {
          id: 12,
          subTitle: "E-COMMERCE WEBSITE",
          title: "MOONSHOT FACTORY",
          details:
            "FRONT-END & BACK-END DEVELOPMENT, HOSTING",
          link: "/moonshot",
          path: "moonshot",
          hasScore: true,
           score: {
            performance: 92,
            structure: 86,
          },
        },
        {
          id: 7,
          subTitle: "Web Application",
          title: "Queensway Anytime Fitness",
          details: "FRONT-END & BACK-END DEVELOPMENT",
          link: "/queenswayaf",
          path: "queenswayAF",
          hasScore: true,
          score: {
            performance: 98,
            structure: 98,
          },
        },
        {
          id: 2,
          subTitle: "CORPORATE WEBSITE",
          title: "WebBricksSG",
          details: "FRONT-END & BACK-END DEVELOPMENT",
          link: "/",
          path: "webbricks_web",
          hasScore: true,
          score: {
            performance: 100,
            structure: 98,
          },
        },
        {
          id: 3,
          subTitle: "PORTFOLIO WEBSITE",
          title: "Syahmi Abbas",
          details: "FRONT-END & BACK-END DEVELOPMENT",
          link: "/syahmi",
          path: "portfolio_sya_web",
          hasScore: true,
          score: {
            performance: 89,
            structure: 100,
          },
        },
        {
          id: 4,
          subTitle: "Telegram Bot",
          title: "Covid-19 stats Bot",
          details: "FRONT-END & API USAGE",
          link: "/covidbot",
          path: "sg_covid_bot",
          hasScore: false,
        },
        {
          id: 5,
          subTitle: "CORPORATE WEBSITE",
          title: "HUG Community Services Limited",
          details: "Modfication, Deploy & Hosting",
          link: "/hug",
          path: "hug_website",
          hasScore: true,
          score: {
            performance: 99,
            structure: 79,
          },
        },
        {
          id: 11,
          subTitle: "ORGANISATION WEBSITE",
          title: "Universal Community Service",
          details:
            "FRONT-END & BACK-END DEVELOPMENT",
          link: "/",
          path: "ucs_web",
          hasScore: true,
           score: {
            performance: 99,
            structure: 97,
          },
        },
      
      ],
    };
  },
  name: "ProjectContent",
  components: {
    ProjectDetails,
  },
};
</script>
<style>
.projectPage__section {
  width: 80vw;
  margin: auto;
}

@media only screen and (min-width: 1024px) {
  .projectPage__section {
    width: 60vw;
    margin: auto;
  }
}
</style>