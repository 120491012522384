<template>
  <router-link
    class="routerLink service__component"
    :to="{ path: `service/${encodeURIComponent(type)}/${service.id}`}"
    tag="div"
  >
    <source
      :srcset="require(`@/assets/images/service/${service.path}.svg`)"
      type="image/jpeg"
    />
    <img
      class="service__pic"
      :src="require(`@/assets/images/service/${service.path}.svg`)"
      width="48px"
    />
    <div class="title">
      <span>{{ service.title }}</span>
    </div>
  </router-link>
</template>
<script>
export default {
  props: ["service","type"],
};
</script>
<style scoped>
.service__component {
  display: flex;
  background: white;
  border-radius: 15px;
  margin: 1em 1em 1em 0;
  width: 31%;
  height:60px;
  text-align: left;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.service__component:hover {
  cursor: pointer;
  background: #26a0da;
  color: white;
}
/* .service__detail>*{
    flex: 0 0 33.3333%;
} */
.service__pic {
  padding: 5px;
}
.title {
  line-height: 58px;
}
span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-weight: bold;
}
@media only screen and (max-width: 892px) {
  .service__component {
    width: 40%;
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .service__component {
    width: 100%;
  }
}
</style>
