<template>
  <div class="service__section">
    <div class="service_title">
      <span>{{ type }}</span>
    </div>
    <br />
    <div class="service__component__container">
      <ServiceComponent
        v-for="service in serviceArr"
        :key="service.id"
        :service="service"
        :type="type"
      />
    </div>
    
  </div>
</template>

<script>
import ServiceComponent from "./ServiceComponent";
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    serviceArr: {
      type: Array,
      required: true,
    },
  },
  components: {
    ServiceComponent,
  },
};
</script>

<style>
.service__section {
  padding-top: 1em;
}

.service_title {
  font-weight: bold;
  color: white;
  text-align: left;
}
.service__component__container {
  display: flex;
  flex-wrap: wrap;
}
.service__component__inner {
  width: 100%;
}
.routerLink {
  text-decoration: none;
}
@media only screen and (max-width: 892px) {
  .service__component__container {
    justify-content: center;
    align-items: center;
  }
}
</style>