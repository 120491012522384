<template>
  <section class="service__group__section">
    <div class="service__group">
      <div class="header">Services</div>
      <br/>
        <ServiceSection
          v-for="service in serviceData.services"
          :key="service.id"
          :serviceArr="service[Object.keys(service)[0]]"
          :type="Object.keys(service)[0]"
        />
    </div>
  </section>
</template>
<script>
import ServiceSection from './ServiceSection';
import ServiceData from "../../assets/servicedata.json";
export default {
  props: ["service"],
  data() {
    return {
      serviceData: ServiceData
    };
  },
  components: {
    ServiceSection
  },
};
</script>
<style scoped>

.header {
  font-weight: 900;
  font-size: 35px;
  color: white;
}
.service__group {
  padding-top: 100px;
  flex-direction: column;
  padding-bottom: 100px;
}
.service__group__section {
  background-color: #37484f;
  margin: auto;
  padding: 0 6rem;
}

@media only screen and (max-width: 768px) {
.service__group__section {
  padding: 0 1.5rem;
}
}
</style>
